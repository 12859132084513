import React, {
    useState,
    useEffect
} from 'react';
import { ReactComponent as HomeIcon } from '../../img/nav-icons/home.svg';
import { ReactComponent as ListIcon } from '../../img/nav-icons/list.svg';
import { ReactComponent as RandomIcon } from '../../img/nav-icons/random.svg';
import { ReactComponent as HeartIcon } from '../../img/nav-icons/heart.svg';
import BottomNavItem from './BottomNavItem/BottomNavItem';
import './bottom-nav-bar.css';

export default function BottomNavBar({ }) {
    return (
        <nav className='bottom-nav'>
            <div className='container'>
                <ul className='menu'>
                    <BottomNavItem to='/' icon={<HomeIcon />} content='Accueil' />
                    <BottomNavItem to='/list' icon={<ListIcon />} content='Liste' />
                    <BottomNavItem to='/random' icon={<RandomIcon />} content='Aléatoire' />
                    <BottomNavItem to='/favorites' icon={<HeartIcon />} content='Favoris' />
                </ul>
            </div>
        </nav>
    )
}